import { FC } from 'react'
import { Question } from '@phosphor-icons/react'

import { IconPropsType } from '~/src/components/generic/PhosphorIcons/types'

const Icon: FC<IconPropsType> = ({
  'aria-label': ariaLabel,
  color,
  mirrored,
  weight,
  size,
}) => (
  <Question
    color={color}
    size={size}
    weight={weight}
    mirrored={mirrored}
    aria-label={ariaLabel}
  />
)

export default Icon
